const IconApple = () => (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.6131 12.607C18.5839 10.0669 20.8321 8.84064 20.9196 8.78225C19.6642 7.05966 17.6788 6.82609 17.0073 6.7969C15.3723 6.62172 13.7665 7.73118 12.9198 7.73118C12.0731 7.73118 10.7593 6.82609 9.38707 6.85529C7.60609 6.88449 5.91269 7.84797 5.00761 9.36618C3.10984 12.4318 4.51127 16.9572 6.35064 19.4389C7.25573 20.6652 8.3068 22.0082 9.70823 21.979C11.0805 21.9206 11.5768 21.1615 13.2118 21.1615C14.8468 21.1615 15.3139 21.979 16.7446 21.9498C18.2044 21.9206 19.1387 20.7236 20.0146 19.4973C21.0656 18.0959 21.4744 16.7237 21.5036 16.6653C21.4452 16.6361 18.6423 15.6434 18.6131 12.607ZM15.9271 5.1911C16.657 4.3444 17.1533 3.17654 17.0073 1.97949C15.9563 2.00869 14.6132 2.65101 13.8541 3.4977C13.1826 4.22761 12.5695 5.45386 12.7447 6.59252C13.9417 6.68011 15.168 6.00859 15.9271 5.1911Z"
            fill="currentColor"
        />
    </svg>
);

export default IconApple;
